.PlayTile-container {
  /* --tile-width: calc(var(--square-size) * 0.06208); */
  /* --tile-width: calc(var(--square-size) * 0.12); */

  /* --tile-width: 50px; */
  --tile-width: calc(var(--height) * 0.115);
  --tile-height: calc(var(--tile-width) * 4 / 3);
  --tile-container-height: calc(var(--tile-height) * 1.18);
}

.PlayTile-container {
  display: inline-block;
  position: relative;
  transition: all 0.32s;
  will-change: transform;

  width: var(--tile-width);
  height: var(--tile-container-height);

  /* border: 1px solid var(--ink);
  background: var(--ink); */
  border: 1px solid var(--rose);
  background: var(--rose);
  box-shadow: var(--glowShadow);

  cursor: pointer;
}

/*------ JUST DRAWN ------*/
.PlayTile-justDrawn {
  margin-left: calc(var(--tile-width) * 0.35);
  margin-right: calc(var(--tile-width) * 0.35);
}

/*------ HOVER STYLES ------*/
.PlayTile-container:hover,
.PlayTile-container:focus {
  box-shadow: var(--glowShadow-raised);
  transform: translateY(-12px);
  transition: all 0.54s;
}

.PlayTile-tip {
  /* border: 1px solid var(--ink); */
  border: 1px solid var(--rose);
  font-size: calc(var(--square-size) * 0.021);
  display: flex;
}

.PlayTile-tipValue {
  color: var(--paper);
  background: var(--rose);
  padding: 0 4px;
}

.PlayTile-tipSuit {
  color: var(--rose);
  background: var(--paper);
  padding: 0 4px;
}

/*------ TILE LAYERS ------*/
.PlayTile-front {
  background-color: var(--white);
  background-position: center;
  /* background-size: cover; */
  background-size: 120%;

  position: absolute;
  top: 18%;
}

.PlayTile-top {
  /* background: linear-gradient(180deg, #c8cbff -3.28%, var(--white) 13.93%); */
  background: linear-gradient(180deg, #ba9898 -3.28%, var(--white) 13.93%);

  position: absolute;
  top: 9%;
}

.PlayTile-back {
  background-color: var(--rose);
}

/*------ TILE HELPERS ------*/
.PlayTile-side {
  width: 100%;
  height: calc(85% - 2px);
}
