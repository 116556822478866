.Table {
  /* --width: calc(var(--square-size) * 1.25); */
  /* --width: calc(var(--square-size) * 2.8); */
  /* --width: calc(var(--square-size) * 2.8); */
  --width: calc(100vw);
  --height: calc(100vh);

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  /* border: 2px dashed #ffffff; */

  width: var(--width);
  height: var(--height);
  margin: auto;

  top: calc(50vh - (var(--height) / 2));
  left: calc(50vw - (var(--width) / 2));
  /* right: calc(40vw - (var(--width) / 2)); */

  /* perspective: 6cm; */

  /* padding-left: env(safe-area-inset-left); */
}

.Player {
  position: absolute;
}

.Table .Player-bottom {
  bottom: 12px;
}
.Table .Player-top {
  top: 10px;
}
.Table .Player-left {
  /* left: calc(env(safe-area-inset-left)); */
  left: 12px;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .Table .Player-left {
    left: calc(env(safe-area-inset-left) - 12px);
    /* left: 12px; */
  }
}
.Table .Player-right {
  right: 12px;
}

.gameStartCountdown {
  position: absolute;
  animation: countdown normal 1s infinite ease-in-out;
  top: 10%;
  transform-origin: 50% 50%;
  font-size: 12rem;
  font-family: "Source Serif Pro";
  z-index: 130;
}

@keyframes countdown {
  0% {
    opacity: 1;
    /* font-size: 5rem; */
    transform: scale(1);
  }
  50% {
    /* opacity: 0.5; */
    /* transform: translateZ(8cm); */
  }
  100% {
    opacity: 1;
    /* font-size: 1rem; */
    transform: scale(0.4);
  }
}
