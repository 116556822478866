/*------ FONT IMPORTS  ------*/
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@600&family=Open+Sans:wght@300;400;500;600;700;800&family=Audiowide&family=Space+Mono:wght@400;700&family=Noto+Serif+SC:wght@400;600&&display=swap");

@font-face {
  font-family: Gameplaya;
  src: url(assets/fonts/Gameplaya.ttf);
}

@font-face {
  font-family: GameplayaItalic;
  src: url(assets/fonts/Gameplaya_Italic.ttf);
}

/*------ COLOR DECLARATIONS  ------*/
/* prettier-ignore */
:root {
  --gold:   #cf8600;
  --poppy:  #ac391b;
  /* --ink:    #262c86; */
  --ink:    #ececec;
  --liquid: #07a7a1;
  --lily:   #6fa38c;
  --jade:   #3a8227;
  --white:  #ffffff;
  --paper:  #f1efec;
  --ash:    #515151;
  --rose:   #830A0A;

  --glowShadow: 0px 8px 7px #4753fc1f;
  --glowShadow-raised: 0px 20px 7px #4753fc14;

  --square-size: 76vh;
}
/* end prettier ignore */

@media (prefers-reduced-motion: no-preference) {
}

/*------ SQUARE SIZE  ------*/
@media (orientation: landscape) {
  :root {
    /* --square-size: 76vh; */
    --square-size: 76vh;
  }
}

/* @media (orientation: portrait) {
  :root {
    --square-size: 72vw;
  }
} */

/*------ BASIC BODY STYLES  ------*/
body {
  font-family: "Space Mono", monospace;
  color: var(--ink);
  /* color: #ececec; */
  overflow: hidden;
  /* background-color: var(--paper); */
  background-color: #9c7e7e;
  min-height: -webkit-fill-available;
}

#root {
  /* width: 100%;
  height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* justify-content: space-between; */
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

a {
  color: var(--poppy);
}

.grecaptcha-badge {
  visibility: hidden;
}

/* #rc-imageselect {
  height: 100%;
} */

#rc-imageselect,
.g-recaptcha {
  transform: scale(0.6);
  transform-origin: 0 0;
}

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) { */
@media screen and (orientation: portrait) and (min-aspect-ratio: 13/9) {
  html {
    /* transform: rotate(90deg);
    transform-origin: center; */
    /* width: 100vh; */
    /* height: 100vw; */
    /* overflow-x: hidden; */
    /* position: absolute; */
    /* top: 100%; */
    /* left: 0;
    right: 0; */
  }
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.appUpdateModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation-name: fade-in;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 0.2s;

  background-color: rgba(87, 145, 107, 0.919);

  width: 100%;
  height: 100%;

  transform: translateZ(3px);
  position: absolute;
  z-index: 150;

  .title {
    width: 60%;
    text-align: center;
    font-size: 1.6rem;
  }

  .confirm-btn {
    margin-top: 24px;
    font-size: 1.5rem;
  }
}
