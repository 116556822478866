.ingame-container {
  background-image: url("/assets/metacasino/ingame/table_bg_sm.png");
  background-repeat: no-repeat;
  background-color: #2a1e16;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;

  // @media screen and (orientation: portrait) {
  //   transform: rotate(-90deg);
  //   // transform-origin: right bottom;
  //   width: 100vh;
  //   height: 100vw;
  // }
  .price-container {
    position: absolute;
    top: 12px;
    left: 4%;
    // width: 140px;
    // height: 20px;
    // z-index: 100;
    color: #ffffff;
    font-size: 0.6rem;
    transform: translateZ(1px);
  }

  .roomIdString {
    position: absolute;
    bottom: 0px;
    right: 4px;
    font-size: 0.4rem;
    color: #c9c9c9c6;
    transform: translateZ(1px);
  }

  .autopilotPrompt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation-name: fade-in;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-duration: 0.2s;

    background-color: rgba(96, 175, 123, 0.85);

    width: 100%;
    height: 100%;

    transform: translateZ(3px);
    position: absolute;
    z-index: 150;

    .title {
      width: 60%;
      text-align: center;
      font-size: 1.6rem;
    }

    .confirm-btn {
      margin-top: 24px;
      font-size: 1.5rem;
    }
  }

  .noRoomData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
