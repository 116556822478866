.welcomePage-container {
  background-image: url("/assets/metacasino/pregame/light_tunnel_bg.jpg");
  background-repeat: no-repeat;
  background-color: #2a1e16;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .gameTitle {
    background-image: url("/assets/metacasino/pregame/game_title.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    height: 50%;
    width: 60%;
    margin-top: 3%;
  }

  .pressStartContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2%;
    animation-direction: alternate;
    animation: pulse 1s infinite ease-in-out;
    transition: all 0.25s ease-in;
    opacity: 1;

    .pressStartEN {
      width: 36%;
    }

    .pressStartCN {
      margin-top: 10px;
      width: 18%;
    }

    &:hover {
      opacity: 0.5;
    }
    &:active {
      opacity: 0.5;
    }
  }

  .version-text {
    position: absolute;
    bottom: 4px;
    right: 12px;
    font-size: 0.7rem;
  }

  .appUpdateModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation-name: fade-in;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-duration: 0.2s;

    background-color: rgba(87, 145, 107, 0.919);

    width: 100%;
    height: 100%;

    transform: translateZ(3px);
    position: absolute;
    z-index: 150;

    .title {
      width: 60%;
      text-align: center;
      font-size: 1.6rem;
    }

    .confirm-btn {
      margin-top: 24px;
      font-size: 1.5rem;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
