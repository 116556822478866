.Menu-button {
  // font-family: "Noto Serif", serif;
  font-family: "Space Mono";
  font-weight: 600;
  font-size: 1.8rem;

  outline: none;
  border: none;
  padding: 12px 12px;

  background: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;

  color: var(--paper);
  opacity: 0.8;
}

.border-button {
  border: 2px solid;
  border-radius: 20px;
  padding: 4px 12px;
  margin-bottom: 24px;

  &:hover,
  &:active {
    background-color: #d6d6d694;
  }
}

.Menu-button:hover {
  opacity: 1;
}

.settings-icon {
  opacity: 0.8;
  color: rgb(255, 255, 255);

  &:hover {
    opacity: 1;
  }
}

.Menu-openButton {
  position: fixed;
  top: 0px;
  right: 7%;

  opacity: 1;
  color: var(--ink);
}
// .Menu-openButton:hover {
//   background-image: url("/assets/Brushes/InkyBrush.svg");
// }

.Menu-container {
  display: flex;
  justify-content: center;
  align-items: center;

  animation-name: fade-in;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 0.2s;

  background-color: rgba(174, 174, 219, 0.866);

  width: 100%;
  height: 100%;
  z-index: 110;
  transform: translateZ(2px);
}

.Menu-content {
  color: var(--paper);
  padding-bottom: 24px;
  position: absolute;
  z-index: 110;
  display: flex;
  flex-direction: column;
}

// .Menu-background {
//   z-index: 0;
// }

.Menu-fadeOut {
  animation-name: fade-out;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 0.2s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
