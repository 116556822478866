.HuAnimation-container {
  position: absolute;
  top: 0;
  // bottom: 0;
  left: 0;
  // right: 0;
  // width: 100px;
  // height: 100px;
  pointer-events: none;
  z-index: 110;
  // background-color: white;
}
