.gamemodeLobby-container {
  background-image: url("/assets/metacasino/lobby/Lobby_UIbackground_sm.png");
  background-repeat: no-repeat;
  background-color: #2a1e16;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  // overflow: scroll;

  .gameRulesPrompt {
    // height: 100vh;
    // width: 100%;

    background-color: rgba(70, 194, 210, 0.93);
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    border-radius: 20px;
    border: 3px solid #ffffff;
    padding-top: 5%;
    animation: zoomOut 0.4s ease-in-out forwards;
    padding: 16px;
    overflow: hidden;

    .closePromptIcon {
      color: #ffffff;
      position: absolute;
      right: 2%;
      top: 2%;
    }

    .gameRulesContainer {
      overflow-y: scroll;
      height: 320px;
      h1 {
        font-size: 1.5rem;
      }

      h2 {
        font-size: 1.2rem;
      }

      h3 {
        font-size: 1rem;
      }

      p,
      li {
        font-size: 0.9rem;
      }
    }
  }

  .insufficientBalancePrompt {
    background-color: #46c2d2e3;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    border-radius: 20px;
    border: 3px solid #ffffff;
    padding-top: 5%;
    animation: zoomOut 0.4s ease-in-out forwards;

    @keyframes zoomOut {
      0% {
        opacity: 0;
        top: 50%;
        left: 50%;
        right: 50%;
        bottom: 50%;
      }
      // 80% {
      //   opacity: 1;
      // }
      70% {
        opacity: 1;
        top: 5%;
        left: 10%;
        right: 10%;
        bottom: 5%;
      }
      100% {
        opacity: 1;
        top: 5%;
        left: 10%;
        right: 10%;
        bottom: 5%;
      }
    }

    .closePromptIcon {
      color: #ffffff;
      position: absolute;
      right: 2%;
      top: 2%;
    }

    .insufficientText {
      color: #ffffff;
      text-align: center;
      font-weight: bold;
      font-size: 1.2rem;

      span {
        // font-family: "Open Sans";
        font-family: "Inter";
        // font-weight: normal;
      }
    }

    .wallet-container {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .wallet-settings-container {
      // color: #000000;
      padding-top: 12px;
      // width: 100%;
      padding-left: 36px;
      padding-right: 36px;

      .settings-label {
        text-align: left;
        color: #ffffff;
        font-weight: bold;
        font-size: 0.9rem;
      }

      .wallet-address-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 85%;

        .settings-input {
          background-color: #ededed;
          padding: 4px 8px;
          border: 4px solid #ffffff;
          border-radius: 14px;
          font-size: 1rem;
          box-sizing: border-box;
          width: 80%;
          font-size: 0.7rem;
          font-family: monospace;
          text-align: center;

          &:disabled {
            background-color: #b9b9b9d5;
            color: #020202;
          }
        }

        .copy-icon {
          // color: #1b1111;
          color: #ffffff;
          outline: none;
          margin-left: 4px;

          &:active {
            color: #8c8c8c;
          }
        }
      }

      .qr-container {
        text-align: center;
        margin-top: 12px;

        .qrcode {
          margin-top: 8px;
          padding: 12px;
          border: 2px #ffffff solid;
          border-radius: 24px;
          background-color: #ffffff;
        }
      }

      .tg-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .first-bonus-text {
          margin-left: 16px;
        }
      }

      .tgSupportBtn {
        // position: absolute;
        width: 90px;
      }
    }
  }

  .metaverseLoadingScreen {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #020202;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .loading-container {
      text-align: center;

      .title,
      .subtitle {
        font-size: 1.5rem;
        margin-bottom: 8px;
      }

      .subtitle {
        margin-bottom: 24px;
      }

      .typewriter {
        overflow: hidden; /* Ensures the content is not revealed until the animation */
        border-right: 0.15em solid orange; /* The typwriter cursor */
        white-space: nowrap; /* Keeps the content on a single line */
        margin: 0 auto; /* Gives that scrolling effect as the typing happens */
        letter-spacing: 0.13em; /* Adjust as needed */
        // animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite;
        animation: typing 2.5s steps(15) 1s 1 normal both, blink-caret 500ms steps(48) infinite normal;
        // font-family: "Audiowide";
        font-family: "Space Mono";
        // width: 300px;
        font-size: 1rem;
      }

      /* The typing effect */
      @keyframes typing {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }

      /* The typewriter cursor effect */
      @keyframes blink-caret {
        from,
        to {
          border-color: transparent;
        }
        50% {
          border-color: orange;
        }
      }
    }
  }

  .matchingOtherPlayersPrompt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation-name: fade-in;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-duration: 0.2s;

    background-color: rgba(87, 145, 107, 0.919);
    width: 100%;
    height: 100%;

    transform: translateZ(3px);
    position: absolute;
    z-index: 150;

    .title {
      width: 60%;
      text-align: center;
      font-size: 1.6rem;
    }
  }

  .rejoinExistingRoomPrompt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation-name: fade-in;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-duration: 0.2s;

    background-color: rgba(87, 145, 107, 0.919);

    width: 100%;
    height: 100%;

    transform: translateZ(3px);
    position: absolute;
    z-index: 150;

    .title {
      width: 60%;
      text-align: center;
      font-size: 1.6rem;
    }

    .confirm-btn {
      margin-top: 24px;
      font-size: 1.5rem;
    }
  }

  .tgSupportBtn {
    margin-top: 8px;
    text-decoration: none;
    // position: absolute;
    // right: 50%;
    // bottom: 20%;
    color: white;

    background-color: rgba(0, 136, 204, 0.8);
    border: 2px solid #ffffff;
    padding: 4px 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 16px;

    transition: all 0.3s;

    &:hover,
    &:active {
      background-color: rgba(0, 136, 204, 1);
    }

    .signout-text {
      font-weight: bold;
      font-size: 1rem;
    }
  }

  .lobby-overlay {
    background-image: url("/assets/metacasino/lobby/Lobby_UIframe.png");
    background-repeat: no-repeat;
    // background-color: #2a1e16;
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .gamemode-page {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: scroll;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .gameTitle {
        background-image: url("/assets/metacasino/lobby/fastpaced_mj_title.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 30px;
        width: 40%;
        position: absolute;
        top: 5%;
        // z-index: 10;
      }
      .balance-container {
        position: absolute;
        top: 12%;
        right: 5%;

        .balance-label {
          color: #2f90a5;
          font-weight: bold;
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .refresh-icon {
            color: #2f90a5;
            margin-left: 4px;
            padding: 2px;
            // transition: all 0.5s;

            // &:active {
            //   transform: rotate(270deg);
            // }
          }

          .refreshing {
            animation: refreshSpin 0.3s linear infinite reverse;
          }

          @keyframes refreshSpin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(180deg);
            }
            // 100% {
            //   otransform: rotate(360deg);
            // }
          }
        }
        .balance-frame {
          background-image: url("/assets/metacasino/lobby/text_frame.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          height: 60px;
          width: 180px;
          margin-top: -10px;
          // z-index: 10;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .balance-value {
            text-align: center;
            color: #2a1e16;
          }
        }
      }

      .tgSupportBtn {
        position: absolute;
        right: 8%;
        bottom: 25%;
      }

      .gameRulesButton {
        background-color: rgb(53, 195, 143);
        border: 2px solid #ffffff;
        padding: 4px 24px;

        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 16px;

        position: absolute;
        right: 8%;
        bottom: 15%;

        transition: all 0.3s;

        &:hover,
        &:active {
          background-color: rgb(45, 160, 118);
        }

        .signout-text {
          font-weight: bold;
          font-size: 1rem;
        }
      }

      .lobby-settings-container {
        position: absolute;
        bottom: 0px;
      }

      .backTitle {
        background-image: url("/assets/metacasino/lobby/lobby_return_button.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 24px;
        width: 40%;
        position: absolute;
        bottom: 8px;
        transition: all 0.3s;

        &:hover,
        &:active {
          transform: scale(1.1);
          transform-origin: center;
        }
      }

      .gamemode-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        // margin-top: 5%;

        .gamemode-button-container {
          height: 100px;
          width: 45%;
        }

        .base-gamemode-button {
          background-image: url("/assets/metacasino/lobby/base_game_mode.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          height: 90px;
          // width: 35%;
          transition: all 0.5s;
          filter: brightness(90%);

          &:hover {
            height: 100px;
            filter: brightness(100%);
          }

          &:active {
            height: 95px;
            filter: brightness(100%);
          }
        }
        .self-drawn-gamemode-button {
          background-image: url("/assets/metacasino/lobby/self_drawn_game_mode.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          height: 90px;
          // width: 35%;
          transition: all 0.5s;
          filter: brightness(90%);

          &:hover {
            height: 100px;
            filter: brightness(100%);
          }

          &:active {
            height: 95px;
            filter: brightness(100%);
          }
        }
      }

      .pricemode-container {
        // color: #000000;
        // font-size: 60px;
        top: 15%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 70%;
        min-height: 280px;
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-top: 2%;
        // z-index: 10;

        .buttonLeft,
        .buttonRight {
          height: 30%;
          width: 100px;
          position: absolute;

          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          top: 40%;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.1);
            transform-origin: center;
          }

          &:active {
            transform: scale(1);
            transform-origin: center;
          }
        }

        .buttonLeft {
          background-image: url("/assets/metacasino/lobby/price_button_left.png");
          left: -14%;
        }

        .buttonRight {
          background-image: url("/assets/metacasino/lobby/price_button_right.png");
          right: -14%;
        }

        .price-selections-container {
          margin-top: 6%;
          text-align: center;
          // padding: 5%;

          .levelLabel {
            padding: 4px 8px;
            font-size: 1.2rem;
            font-weight: bold;
            // padding-top: 20px;
          }

          .price-option {
            padding: 4px 8px;
            margin-bottom: 6px;
            transition: all 0.3s;
            font-family: "Open Sans";
            font-weight: bold;
            background-color: rgba(135, 135, 135, 0.702);
            border-radius: 4px;
            border-left: 6px solid rgba(135, 135, 135, 0.702);
            border-right: 6px solid rgba(135, 135, 135, 0.702);
            // background-clip: padding-box;

            &:hover {
              border-left: 6px solid #ffffff;
              border-right: 6px solid #ffffff;
            }
          }

          .option-selected {
            color: #00aa2d;
            border-left: 6px solid #ffffff;
            border-right: 6px solid #ffffff;
            background-color: #ffffff;
          }
        }
      }

      .start-game-button {
        // font-family: "Noto Serif SC";
        font-weight: bold;
        font-size: 1.5rem;
        color: #ffffff;
        position: absolute;
        bottom: 42px;
        border: 2px solid #ffffff;
        border-radius: 12px;
        padding: 4px 24px;
        background-clip: padding-box;
        background-color: #ffaa00;
      }

      .gamemode-BASIC {
        background-image: url("/assets/metacasino/lobby/frame_base_price.png");
      }

      .gamemode-SELF_DRAWN {
        background-image: url("/assets/metacasino/lobby/frame_self_drawn_price.png");
      }
    }
  }
}
