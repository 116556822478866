.auth-container {
  background-image: url("/assets/metacasino/pregame/light_tunnel_bg.jpg");
  background-repeat: no-repeat;
  background-color: #2a1e16;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;

  .gameTitle {
    background-image: url("/assets/metacasino/pregame/game_title.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    height: 30%;
    width: 40%;
    margin-top: 3%;
    z-index: 10;
  }

  .loading-container {
    text-align: center;

    .typewriter {
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      border-right: 0.15em solid orange; /* The typwriter cursor */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      letter-spacing: 0.15em; /* Adjust as needed */
      animation: typing 3.5s steps(40, end), blink-caret 0.5s step-end infinite;
      font-family: "Audiowide";
    }

    /* The typing effect */
    @keyframes typing {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }

    /* The typewriter cursor effect */
    @keyframes blink-caret {
      from,
      to {
        border-color: transparent;
      }
      50% {
        border-color: orange;
      }
    }
  }

  .auth-frame {
    // background-image: url("/assets/metacasino/pregame/auth_frame.png");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 100% 100%;
    margin-top: -5%;
    border-radius: 18px;
    border: #ffffff solid 5px;
    // max-height: 50%;
    // height: 60%;
    height: auto;
    width: 28%;
    min-width: 400px;
    background-color: #c1c1c198;
    font-weight: bold;

    .login-container {
      height: 100%;
      // padding: 20px;

      .form-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        padding-bottom: 12px;

        input,
        button {
          margin-top: 8px;
        }

        .login-btn {
          border-radius: 12px;
          outline: none;
          border: 1px solid #3b5bff00;
          padding: 8px 12px;
          width: calc(200px - 24px - 2px);
          // min-width: 280px;
          // background-color: #3b5bff;
          background-image: linear-gradient(to right, #3b5bff 0%, #b131ff 51%, #3b5bff 100%);
          background-size: 200% auto;

          color: #ffffff;
          transition: all 0.3;

          &:hover {
            background-position: right center; /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
          }

          &:disabled {
            background-image: none;
            background-color: #cccccc;
          }
        }

        .auth-input {
          box-sizing: border-box;
          border-radius: 12px;
          outline: none;
          border: 1px solid #cccccc;
          box-shadow: 0 0 10px #ffffff;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          padding: 10px 12px;
          width: 75%;
        }

        .verification-code-input {
        }

        .referral-code-input {
          text-align: center;
          // width: 40%;
          width: calc(200px - 24px - 2px);
          caret-color: #0000004f;
          text-transform: uppercase;
        }

        .form-title {
          margin-top: 12px;
        }

        .subtitle {
          font-family: Gameplaya;
          letter-spacing: 0.05rem;
        }

        .phone-row {
          display: flex;
          flex-direction: row;
          width: 75%;

          .send-code-button {
            border-radius: 12px;
            outline: none;
            border: 1px solid #3b5bff00;
            padding: 2px 4px;
            width: 35%;
            // min-width: 280px;
            // background-color: #3b5bff;
            background-image: linear-gradient(to right, #3b5bff 0%, #b131ff 51%, #3b5bff 100%);
            background-size: 200% auto;
            margin-top: 11px;
            color: #ffffff;
            margin-left: 8px;
          }

          .phone-container {
            color: black;
            // width: auto;
            width: 75%;
            // min-width: 280px;
            margin-top: 12px;
          }

          .phone-input-class {
            border-radius: 12px;
            border: 1px solid #ffffff;
            box-shadow: 0 0 10px #ffffff;
            width: 100%;
          }

          .phone-dropdown-class {
            border-radius: 12px;
            border: 1px solid #ffffff;
            // box-shadow: 0 0 10px #ffffff;
            // width: 100%;

            .selected-flag {
              border-radius: 12px;
            }
          }
        }

        .phoneError,
        .loginError {
          margin-top: 4px;
          font-size: 0.75rem;
          font-family: "Open Sans";
          font-weight: 400;
          color: rgb(255, 186, 186);
          // align-self: baseline;
        }

        .sub-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;

          .signup-link {
            margin: 8px 12px;
          }

          .forgot-pw-link {
            margin: 8px 12px;
          }
        }
      }
    }

    // .react-tel-input {
    //   .form-control {
    //     width: 100%;
    //   }

    //   .flag-dropdown {
    //     .country-list {
    //       width: auto;
    //     }
    //   }
    // }
  }
}
