.confirmationModal-container {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 25%;
  right: 25%;
  background-color: #8ae4b8ee;
  // background-color: #b2d4c3ee;
  border-radius: 36px;
  border: 3px solid #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    color: #ffffff;
    font-size: 1.5rem;
    margin-top: 12px;
    font-weight: bold;
  }
}

.settings-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .settingsTitle {
    background-image: url("/assets/metacasino/settings/setting_title.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    height: 30px;
    width: 40%;
    position: absolute;
    top: 5%;
    // z-index: 10;
  }

  .logoutButton {
    // background-color: #37b7d3;
    background-color: rgba(211, 93, 54, 0.8);
    border: 2px solid #ffffff;
    padding: 4px 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 16px;

    position: absolute;
    right: 6%;
    top: 20%;

    transition: all 0.3s;

    &:hover,
    &:active {
      background-color: rgba(211, 93, 54, 1);
    }

    .signout-text {
      font-weight: bold;
      font-size: 1rem;
    }
  }

  .settings-container {
    position: absolute;
    top: 20%;
    height: 80%;
    width: 50%;

    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .tabs-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .tab {
        font-size: 0.9rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.7);
        background-color: #ededed;
        padding: 4px 24px;
        border: 4px solid #ffffff;
        border-radius: 14px;
        opacity: 0.6;
        filter: brightness(0.8);
        margin: 0 4px;
        transition: all 0.3s;
        min-width: 58px;
        text-align: center;
      }

      .tab-active {
        box-shadow: 0 0 10px #ffffff;
        opacity: 1;
        filter: brightness(1);
        color: rgba(0, 0, 0, 1);
      }

      .account-settings-tab {
      }

      .wallet-settings-tab {
      }
    }

    .settings-input {
      background-color: #ededed;
      padding: 4px 8px;
      border: 4px solid #ffffff;
      border-radius: 14px;
      font-size: 1rem;
      box-sizing: border-box;
      width: 100%;

      &:disabled {
        background-color: #b8b6b6c7;
        color: #494949;
      }
    }

    .settings-label-td {
      width: 35%;
      color: #000000;
    }

    .address-label {
      font-size: 0.75rem;
      margin: 4px 0;
      font-weight: bold;
    }

    .settings-input-td {
      width: 65%;
    }

    // .settings-row-tr {
    //   margin-bottom: 4px;
    // }

    .save-settings-button,
    .submit-button {
      font-weight: bold;
      font-size: 1.2rem;
      color: #ffffff;
      // position: absolute;
      bottom: 48px;
      border: 2px solid #ffffff;
      border-radius: 12px;
      padding: 4px 24px;
      background-clip: padding-box;
      background-color: #ffaa00;
      margin-top: 24px;
      width: 120px;
      text-align: center;
    }

    .account-settings-container {
      // position: absolute;
      // top: 20%;
      padding-top: 24px;
      // border: 3px dashed #ffffff;
      width: 100%;
      height: 50%;
      color: #000000;
      display: flex;
      flex-direction: column;
      align-items: center;
      // background-color: red;

      table {
        width: 100%;
        table-layout: fixed;
      }

      // .label-col {
      //   width: 30%;
      // }
      // .input-col {
      //   width: 70%;
      // }
    }

    .wallet-container {
      display: flex;
      flex-direction: row;
    }

    .wallet-settings-container {
      color: #000000;
      padding-top: 12px;
      width: 100%;

      .settings-label {
        text-align: left;
        font-weight: bold;
        font-size: 0.75rem;
      }

      .wallet-address-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 85%;

        .wallet-address-input {
          font-size: 0.68rem;
          font-family: monospace;

          &:disabled {
            background-color: #b8b6b6c7;
            color: #333333;
          }
        }

        .copy-icon {
          color: #494949;
          outline: none;

          &:active {
            color: #8c8c8c;
          }
        }
      }

      .qr-container {
        text-align: right;
        margin-left: 12px;

        .qrcode {
          background-color: #ffffff;
          margin-top: 8px;
          padding: 6px;
          border: 2px #494949c9 solid;
          border-radius: 12px;
        }
      }

      .tg-container {
        display: flex;
        flex-direction: row;

        .first-bonus-text {
          margin-left: 16px;
        }
      }

      .tgSupportBtn {
        text-decoration: none;
        // position: absolute;
        width: 120px;
        // right: 50%;
        // bottom: 20%;
        color: white;

        background-color: rgba(0, 136, 204, 0.8);
        border: 2px solid #ffffff;
        padding: 4px 24px;

        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 16px;

        transition: all 0.3s;

        &:hover,
        &:active {
          background-color: rgba(0, 136, 204, 1);
        }

        .signout-text {
          font-weight: bold;
          font-size: 1rem;
        }
      }
    }

    .withdraw-settings-container {
      padding-top: 24px;
      width: 120%;

      .withdraw-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .settings-label-td {
        vertical-align: top;
        text-align: right;
        .settings-label {
          margin-top: 8px;
          margin-right: 16px;
        }
      }

      .td-override {
        min-width: 330px;

        .withdraw-wallet-input {
          font-size: 0.7rem;
          font-family: monospace;
          // min-width: 200px;
          padding: 8px 8px;
        }
      }

      .withdraw-amount-input {
        color: #000000;
        width: 50%;
        font-size: 1rem;
        padding: 8px 12px;

        &::placeholder {
          font-size: 0.8rem;
        }
      }

      .withdraw-amount-unit {
        color: #000000;
        margin-left: 4px;
      }
      .max-amount-text {
        color: #000000;
        margin-top: 8px;
        margin-left: 8px;
        font-size: 0.8rem;
        // margin-bottom: 12px;
      }

      .error-text {
        color: red;
        min-height: 16px;
        font-size: 0.8rem;
        margin-left: 8px;
        // margin-bottom: 12px;
      }

      .submit-button {
        margin-top: 0;
      }
    }
  }

  .backTitle {
    background-image: url("/assets/metacasino/lobby/lobby_return_button.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    height: 24px;
    width: 40%;
    position: absolute;
    bottom: 8px;
    transition: all 0.3s;

    &:hover,
    &:active {
      transform: scale(1.1);
      transform-origin: center;
    }
  }
}
