.GameEnd-container {
  // /* --total-width: calc(var(--square-size) * 1.08); */
  // --stamp-width: calc(var(--square-size) * 0.24);
  // --tile-width: calc(var(--square-size) * 0.06208);
  // --text-width: calc(var(--tile-width) * 15);

  // display: flex;
  // gap: calc(var(--stamp-width) * 0.2);

  background-image: url("/assets/metacasino/ingame/table_bg_sm.png");
  background-repeat: no-repeat;
  background-color: #2a1e16;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;

  position: relative;
}

.GameEnd-frame {
  position: absolute;
  top: 2%;
  left: 2%;
  right: 2%;
  bottom: 2%;

  background-image: url("/assets/metacasino/ingame/result_background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  .GameEnd-title {
    position: absolute;
    top: -6%;
    left: calc((100% - 200px) / 2);
    right: 50%;
    width: 200px;
    height: 100px;

    background-image: url("/assets/metacasino/ingame/result_title.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .GameEnd-result-container {
    position: absolute;
    top: 10%;
    left: 3%;
    right: 3%;
    display: flex;
    flex-direction: column;

    .GameEnd-table {
      width: 100%;
      display: table;

      .th-Title {
        font-weight: bold;
        font-size: 1.2rem;
      }

      td {
        text-align: center;
        vertical-align: middle;
      }

      .GameEnd-result-row {
        // padding-bottom: 24px;

        .playerProfile {
          position: relative;
          padding-bottom: calc(var(--square-size) * 0.18);

          .playerProfileFrame {
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 50px;
            height: 50px;
            margin-bottom: 12px;
            z-index: 10;
            top: 0;
            left: 0;

            &.winnerFrame {
              background-image: url("/assets/metacasino/ingame/gameEnd_result/winner_frame.png");
              width: 72px;
              height: 72px;
              top: -20px;
              left: -12px;
            }

            &.normalFrame {
              background-image: url("/assets/metacasino/ingame/gameEnd_result/normal_frame.png");
            }
          }

          .hexagon {
            overflow: hidden;
            // background-color: #c4c4c4;
            transform: rotate(120deg);
            visibility: hidden;
          }

          .hexagon1 {
            // margin: 0 0 0 -80px;
            position: absolute;
            top: 2;
            left: -24px;

            &.winner {
              width: 96px;
              height: 48px;
              top: 2;
              left: -24px;
            }

            &.nonwinner {
              width: 80px;
              height: 40px;
              top: 4px;
              left: -16px;
            }
          }

          .hexagon-in1 {
            overflow: hidden;
            width: 100%;
            height: 100%;
            background-color: #c4c4c4;
            transform: rotate(-60deg);

            .playerPfp {
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              background-position: 50% 50%;
              // background-image: url("/assets/metacasino/pfp/5.png");
              visibility: visible;
              transform: rotate(-60deg);
              background-size: 60% 110%;
            }
          }

          // .playerPfp {
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   background-image: url("/assets/metacasino/pfp/5.png");
          //   background-repeat: no-repeat;
          //   background-position: center;
          //   background-size: contain;
          //   width: 40px;
          //   height: 40px;
          //   z-index: 1;
          // }
        }

        .playerName {
          font-weight: bold;
          font-size: 1rem;
          line-height: 1.2rem;
          // padding-left: 5%;
          // padding-right: 3rem;
        }

        .playerPointChanges {
          font-weight: bold;
          font-family: "Open Sans";
          font-size: 1.2rem;
        }

        .text-green {
          color: #2ffb2f;
          text-shadow: 1px 2px 2px #414040;
        }

        .text-red {
          color: rgb(255, 140, 140);
          text-shadow: 1px 2px 2px #414040;
        }

        .playerHand {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: stretch;

          .tile {
            $tile-width: calc(var(--square-size) * 0.09);
            // $tile-width: calc(100% / 14);
            width: $tile-width;
            height: calc($tile-width * 4 / 3);
            // height: calc(25px * 4 / 3);

            &.bighand {
              $tile-width: calc(var(--square-size) * 0.075);
              width: $tile-width;
              height: calc($tile-width * 4 / 3);
            }
          }
        }
      }
    }

    .GameEnd-actionButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // margin-top: 20px;

      .btn {
        width: 140px;
        height: 40px;
        text-align: center;
        color: #2a1e16;
        padding-top: 14px;
        margin-left: 24px;
        margin-right: 24px;
      }

      .btn-backToLobby {
        background-image: url("/assets/metacasino/ingame/gameEnd_result/backBtn.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .btn-continue {
        background-image: url("/assets/metacasino/ingame/gameEnd_result/backBtn.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
  }
}

.PrizeHorse-container {
  background-image: url("/assets/metacasino/ingame/table_bg_sm.png");
  background-repeat: no-repeat;
  background-color: #2a1e16;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;

  position: relative;

  .PrizeHorse-frame {
    position: absolute;
    top: 2%;
    left: 2%;
    right: 2%;
    bottom: 2%;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url("/assets/metacasino/ingame/result_background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .PrizeHorse-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      font-size: 2rem;
      // font-family: "Noto Serif SC";
      font-weight: bold;
      padding-top: 3%;
    }
    .PrizeHorse-result-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      width: 40%;
      padding-top: 2%;
      position: relative;

      .tile-container {
        .tile {
          $tile-width: calc(var(--square-size) * 0.2);
          // $tile-width: calc(100% / 14);
          width: $tile-width;
          height: calc($tile-width * 4 / 3);
          // height: calc(25px * 4 / 3);

          &.matched {
            border: 2px solid rgba(58, 235, 255, 1);
            // box-shadow: 0 0 8px 5px #dfff9a;
            animation: tileGlow alternate 0.5s infinite linear;
          }
        }

        .matchedFrame {
          position: absolute;
          color: rgb(223, 74, 74);
          text-shadow: 1px 1px rgb(113, 113, 113);
          top: 50px;
          border: 3px solid red;
          padding: 1px 14px;
          font-weight: bold;
          font-size: 1rem;
          background-color: rgba(240, 200, 200, 0.484);
          box-shadow: 1px 1px 4px rgb(113, 113, 113);
          // mix-blend-mode: lighten;
          transform: rotate(-25deg);
          translate: 40% 90%;
        }
      }
    }

    .winnerText {
      margin-top: 1.5rem;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .prizeHorseText {
      // margin-top: 0.5rem;
      font-size: 1.2rem;
      font-weight: bold;

      span {
        font-size: 2rem;
        text-shadow: 1px 1px 8px rgba(58, 235, 255, 1);
        // font-family: "Open Sans";
      }
    }

    .toSummaryButton-container {
      position: absolute;
      top: 60%;
      right: 10%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .toSummaryButton {
        height: 100px;
        width: 100px;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;

        transition: all 0.3s;
        // position: absolute;
        background-image: url("/assets/metacasino/lobby/price_button_right.png");
      }

      .toSummaryText {
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 4px;
        // position: absolute;
        // top: 50%;
        // right: 20%;
      }
    }

    .PrizeHorse-winner-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: 2%;

      .playerProfile {
        // margin-top: 5%;
        position: relative;
        padding-bottom: calc(var(--square-size) * 0.18);
        width: 60px;

        .playerProfileFrame {
          position: absolute;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          width: 50px;
          height: 50px;
          margin-bottom: 12px;
          z-index: 10;
          top: 0;
          left: 0;

          &.winnerFrame {
            background-image: url("/assets/metacasino/ingame/gameEnd_result/winner_frame.png");
            width: 72px;
            height: 72px;
            top: -20px;
            left: -12px;
          }

          &.normalFrame {
            background-image: url("/assets/metacasino/ingame/gameEnd_result/normal_frame.png");
          }
        }

        .hexagon {
          overflow: hidden;
          // background-color: #c4c4c4;
          transform: rotate(120deg);
          visibility: hidden;
        }

        .hexagon1 {
          // margin: 0 0 0 -80px;
          position: absolute;
          top: 2;
          left: -24px;

          &.winner {
            width: 96px;
            height: 48px;
            top: 2;
            left: -24px;
          }

          &.nonwinner {
            width: 80px;
            height: 40px;
            top: 4px;
            left: -16px;
          }
        }

        .hexagon-in1 {
          overflow: hidden;
          width: 100%;
          height: 100%;
          background-color: #c4c4c4;
          transform: rotate(-60deg);

          .playerPfp {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            // background-image: url("/assets/metacasino/pfp/5.png");
            visibility: visible;
            transform: rotate(-60deg);
            background-size: 60% 110%;
            // background-size: contain;
          }
        }
      }
    }
  }
}

.matchingOtherPlayersPrompt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation-name: fade-in;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-duration: 0.2s;

  background-color: rgba(87, 145, 107, 0.919);
  width: 100%;
  height: 100%;

  transform: translateZ(3px);
  position: absolute;
  z-index: 150;

  .title {
    width: 60%;
    text-align: center;
    font-size: 1.6rem;
  }
}

@keyframes tileGlow {
  0% {
    box-shadow: 0 0 5px 4px rgba(58, 235, 255, 0.6);
  }
  50% {
    box-shadow: 0 0 6px 5px rgba(58, 235, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 7px 6px rgba(58, 235, 255, 1);
  }
  // 100% {
  //   box-shadow: 0 0 7px 5px rgba(58, 235, 255, 0.75);
  // }
}

.GameEnd-stamp-border {
  position: relative;
  display: flex;

  width: var(--stamp-width);
  height: var(--stamp-width);
  padding: calc(var(--stamp-width) * 0.04);

  border: calc(var(--stamp-width) * 0.04) solid var(--ink);
}

.GameEnd-stamp-fill {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2%;
  width: 100%;
  height: 100%;

  background: var(--ink);
  color: var(--paper);
  font-size: calc(var(--stamp-width) * 0.7);
}

.GameEnd-stamp-fill.draw {
  font-size: calc(var(--stamp-width) * 0.32);
}

.GameEnd-textSpace {
  width: var(--text-width);
}

.GameEnd-textSpace h2 {
  margin: 0;

  height: calc(var(--stamp-width) * 0.6);
  font-size: calc(var(--stamp-width) * 0.5);
  font-weight: 400;
  line-height: 1.2;
  white-space: nowrap;
}
