.AnAction {
  /* --action-height: calc(var(--square-size) * 0.05317);

  cursor: pointer;

  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: var(--action-height); */
  /* height: calc(var(--square-size) * 0.2);
  width: calc(var(--width) * 0.); */
  height: 70px;
  width: 80px;
}

/* .AnAction {

} */

/* .AnAction-CN {
  position: absolute;

  opacity: 0.8;
  transition: opacity 0.2s;

  font-size: var(--action-height);
  font-family: "Noto Serif SC";
  font-weight: bold;
} */
/*
.AnAction-EN {
  margin-top: calc(var(--action-height) * 0.12);

  line-height: 1;
  text-transform: uppercase;
  font-size: calc(var(--action-height) * 1);
  font-family: "Space Mono";
  font-weight: bold;
}

.AnAction-details {
  font-size: calc(var(--action-height) * 0.36);
  line-height: 1;
}

.AnAction:hover .AnAction-CN {
  opacity: 0.35;
} */

.AnAction {
  animation: fadeIn 0.8s ease-in-out;
  // position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  // animation-iteration-count: 1;
  // animation-delay: 0.5s;
  // opacity: 0;
}

.Chi.AnAction {
  color: var(--poppy);
}

.action-Draw {
  color: var(--ink);
  background-image: url("/assets/metacasino/ingame/seat_actions/draw.png");

  z-index: 99;
}

.action-Pong {
  color: var(--jade);
  background-image: url("/assets/metacasino/ingame/seat_actions/pong.png");

  z-index: 99;
}

.action-Gong {
  color: var(--liquid);
  background-image: url("/assets/metacasino/ingame/seat_actions/gong.png");

  z-index: 99;
}

.action-AnGang {
  color: var(--liquid);
  background-image: url("/assets/metacasino/ingame/seat_actions/gong.png");

  z-index: 99;
}

.action-AddGang {
  color: var(--liquid);
  background-image: url("/assets/metacasino/ingame/seat_actions/gong.png");

  z-index: 99;
}

.action-Hu {
  color: var(--gold);
  background-image: url("/assets/metacasino/ingame/seat_actions/hu.png");

  z-index: 100;
}

.action-Hu-Selfdrawn {
  color: var(--gold);
  background-image: url("/assets/metacasino/ingame/seat_actions/selfdrawn.png");

  z-index: 100;
}

.action-Skip {
  color: var(--ash);
  background-image: url("/assets/metacasino/ingame/seat_actions/skip.png");

  z-index: 99;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.action-wrapper {
  position: absolute;
  // background-color: rgb(136, 136, 136, 0.8);
  width: 80px;
  height: 80px;
}

.action-Draw-wrapper {
  right: -10px;
  bottom: 70px;
}

.action-Pong-wrapper {
  right: -90px;
  bottom: 20px;
}
.action-Gong-wrapper,
.action-AnGang-wrapper,
.action-AddGang-wrapper {
  right: 70px;
  bottom: 20px;
}

.action-Hu-wrapper {
  right: -10px;
  bottom: 70px;
}
.action-Hu-Selfdrawn-wrapper {
  right: -10px;
  bottom: 70px;
}
.action-Skip-wrapper {
  right: -10px;
  bottom: -30px;
}
