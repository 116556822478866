.Seat-tray {
  --tray-short-side: calc(var(--square-size) * 0.1);
  --gap: calc(var(--square-size) * 0.03);

  position: relative;
  // transform: translateZ(7cm);
  // border: 1px solid var(--ink);
  border: 1px solid rgba(236, 236, 236, 0.4);
}

.Seat-top {
  // width: var(--square-size);
  width: calc(var(--width) * 0.7);
  height: var(--tray-short-side);
}

.Seat-bottom {
  /* width: calc(var(--square-size) * 2.4); */
  width: calc(var(--width) * 0.86);
  height: var(--tray-short-side);
}

.Seat-left,
.Seat-right {
  width: var(--tray-short-side);
  height: calc(var(--square-size) * 1.1);
}

/*------ SEAT TILES #01F2DF ------*/
.Seat-tiles {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Seat-left .Seat-tiles,
.Seat-right .Seat-tiles {
  flex-direction: column;
  width: min-content;
  margin: calc(var(--square-size) * 0.01);
  left: 50%;
  transform: translateX(-100%);
}
.Seat-top .Seat-tiles,
.Seat-bottom .Seat-tiles {
  height: min-content;
  margin: calc(var(--square-size) * 0.01);

  top: 50%;
  transform: translateY(-100%);
}

.Seat-bottom .Seat-tiles {
  height: 42%;
  width: unset;
  align-items: flex-end;
  transform: translateY(-50%);
  left: 30px;
}
.Seat-top .Seat-tiles {
  right: 0;
}
.Seat-right .Seat-tiles {
  bottom: 0;
}
.Seat-left .Seat-tiles {
  top: 0;
}

/*------ SEAT TILE NUMBER #01F2DF ------*/
.Seat-actions {
  position: absolute;

  bottom: calc(var(--square-size) * 0.35);
  // right: calc(var(--square-size) * 0.042);
  right: calc(var(--width) * 0.08);
  // left: 0;
  // top: 0;
  // height: 50%;

  // width: 100px;
  // height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Seat-actions .AnAction {
  // margin: calc(var(--square-size) * 0.03);
}

/*------ SEAT OPEN TILES #01F2DF ------*/
.Seat-openTiles {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Seat-bottom .Seat-openTiles {
  right: var(--gap);
  bottom: var(--tray-short-side);
}
.Seat-top .Seat-openTiles {
  // left: var(--gap);
  left: -10%;
  top: var(--tray-short-side);
}
.Seat-right .Seat-openTiles {
  top: var(--gap);
  right: var(--tray-short-side);

  transform: rotate(-90deg) translateY(-100%);
  transform-origin: right top;
  image-rendering: optimizequality;
}
.Seat-left .Seat-openTiles {
  bottom: var(--gap);
  left: var(--tray-short-side);

  transform: rotate(90deg) translateX(-100%);
  transform-origin: left bottom;
  image-rendering: optimizequality;
}

.Seat-meld {
  display: flex;
  margin: 4px;
}

/*------ SEAT WIND #01F2DF ------*/
.Seat-wind {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--ink);
  font-family: "Space Mono";
  font-size: calc(var(--tray-short-side) * 0.25);
}

.Seat-left .Seat-wind,
.Seat-right .Seat-wind {
  writing-mode: vertical-lr;
  text-orientation: upright;
}
.Seat-left .Seat-wind-EN,
.Seat-right .Seat-wind-EN {
  letter-spacing: -0.32em;
}

.Seat-bottom .Seat-wind {
  top: 103%;
  left: 0;
  right: 3%;
  flex-direction: row;
}
.Seat-top .Seat-wind {
  bottom: 103%;
  left: 3%;
  right: 0;
  flex-direction: row-reverse;
}
.Seat-right .Seat-wind {
  left: 107%;
  top: 3%;
  bottom: 0;
  flex-direction: row-reverse;
}
.Seat-left .Seat-wind {
  right: 107%;
  top: 0;
  bottom: 3%;
  flex-direction: row;
}

.Seat-wind-line {
  flex: 1;
  border-top: 1px solid var(--ink);
  border-right: 1px solid var(--ink);
}

.Seat-bottom .Seat-wind-line {
  height: 0;
  margin-right: 8px;
}
.Seat-top .Seat-wind-line {
  height: 0;
  margin-left: 8px;
}
.Seat-right .Seat-wind-line {
  width: 0;
  margin-top: 8px;
}
.Seat-left .Seat-wind-line {
  width: 0;
  margin-bottom: 8px;
}

/*------ SEAT TILE NUMBER #01F2DF ------*/
.Seat-tileNumber {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  color: var(--paper);
  background-color: rgba(236, 236, 236, 0.2);

  font-family: "Space Mono";
  font-size: calc(var(--tray-short-side) * 0.2);
}

.Seat-left .Seat-tileNumber,
.Seat-right .Seat-tileNumber {
  width: var(--tray-short-side);
  height: calc(var(--square-size) * 0.03);

  left: 50%;
  transform: translateX(-50%);
}
.Seat-top .Seat-tileNumber,
.Seat-bottom .Seat-tileNumber {
  flex-direction: column;
  width: calc(var(--square-size) * 0.03);
  height: var(--tray-short-side);

  top: 50%;
  transform: translateY(-50%);
}
.Seat-bottom .Seat-tileNumber {
  right: -1px;
}
.Seat-top .Seat-tileNumber {
  left: -1px;
}
.Seat-right .Seat-tileNumber {
  top: -1px;
}
.Seat-left .Seat-tileNumber {
  bottom: -1px;
}

.Seat-top .Seat-tileNumber-slash,
.Seat-bottom .Seat-tileNumber-slash {
  text-orientation: mixed;
  writing-mode: vertical-lr;
}

/*------ SEAT NAME #01F2DF ------*/
.Seat-name {
  position: absolute;
  // left: 4px;
  font-family: "Space Mono";
  font-size: calc(var(--tray-short-side) * 0.4);
  // text-transform: lowercase;
}

.Seat-left .Seat-name {
  left: 50%;
  transform: translateX(-5%) translateY(-5%) rotate(180deg);
  writing-mode: vertical-lr;
  letter-spacing: 0.15em;
}

.Seat-right .Seat-name {
  left: 50%;
  transform: translateX(-50%) translateY(5%);

  writing-mode: vertical-lr;
  // text-orientation: upright;
  // letter-spacing: -0.32em;
  letter-spacing: 0.15em;
}
.Seat-top .Seat-name,
.Seat-bottom .Seat-name {
  top: 50%;
  transform: translateY(-50%);
  letter-spacing: 0.15em;
}
.Seat-bottom .Seat-name {
  right: 3%;
}
.Seat-top .Seat-name {
  left: 3%;
}
.Seat-right .Seat-name {
  top: 5%;
}
.Seat-left .Seat-name {
  bottom: 5%;
}
