.OpenTile-container {
  // --tile-width: calc(var(--square-size) * 0.0443);
  --tile-width: calc(var(--square-size) * 0.07);
  --tile-height: calc(var(--tile-width) * 4 / 3);
}

.OpenTile-container {
  display: inline-block;
  position: relative;
  transition: all 1s;

  width: var(--tile-width);
  height: var(--tile-height);

  border: 1px solid var(--ink);
  background-position: center;
  // background-size: cover;
  background-size: 120%;
  background-color: white;

  cursor: pointer;
}

.OpenTile-container.selected {
  background-color: var(--white);
}
