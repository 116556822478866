.ClosedTile-container {
  --tile-width: 17px;
  --tile-height: 7px;

  display: inline-block;
  position: relative;
  transition: all 1s;

  width: var(--tile-width);
  height: var(--tile-height);

  /* border: 1px solid var(--ink);
  background-color: var(--ink); */

  border: 1px solid var(--rose);
  /* background-color: var(--rose); */
  background-color: #ffffff;
  opacity: 0.8;
}

.ClosedTile-vertical {
  width: var(--tile-height);
  height: var(--tile-width);
}
