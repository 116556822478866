.profileSetup-container {
  background-image: url("/assets/metacasino/pregame/light_tunnel_bg.jpg");
  background-repeat: no-repeat;
  background-color: #2a1e16;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;

  .gameTitle {
    background-image: url("/assets/metacasino/pregame/game_title.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    height: 30%;
    width: 40%;
    margin-top: 3%;
    z-index: 10;
  }

  .profile-frame {
    margin-top: -5%;
    border-radius: 18px;
    border: #ffffff solid 5px;
    // max-height: 50%;
    height: 70%;
    // height: auto;
    width: 28%;
    min-width: 400px;
    background-color: #c1c1c198;
    font-weight: bold;

    .profile-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      // padding: 20px;

      .profile-circle {
        border: 3px solid #ffffff;
        background-color: #dad8d8bd;
        height: 72px;
        width: 72px;
        border-radius: 80px;
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .profile-icon {
          width: 50px;
          height: 50px;
          color: #ffffff;
        }

        .img-preview {
          height: 85px;
          width: 85px;
          object-fit: cover;
        }

        .hidden {
          display: none;
        }
      }

      .profile-setting-input {
        box-sizing: border-box;
        border-radius: 12px;
        outline: none;
        border: 1px solid #cccccc;
        box-shadow: 0 0 10px #ffffff;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        padding: 10px 12px;
        margin-top: 8px;
        text-align: center;
        caret-color: #0000004f;

        &::placeholder {
          text-align: center;
        }
      }

      .username-input {
        width: 50%;
      }

      .referral-code-input {
        width: 50%;
        text-transform: uppercase;
      }

      .referralCodeError {
        margin-top: 4px;
        font-size: 0.75rem;
        font-family: "Open Sans";
        font-weight: 400;
        color: rgb(255, 186, 186);
      }

      .finish-btn {
        text-align: center;
        border-radius: 12px;
        outline: none;
        border: 1px solid #3b5bff00;
        padding: 8px 12px;
        // width: calc(200px - 24px - 2px);
        width: 200px;
        // min-width: 280px;
        // background-color: #3b5bff;
        background-image: linear-gradient(to right, #3b5bff 0%, #b131ff 51%, #3b5bff 100%);
        background-size: 200% auto;
        margin-top: 12px;
        color: #ffffff;
        transition: all 0.3s;

        &:disabled {
          background-image: none;
          background-color: rgba(204, 204, 204, 0.6);
          color: rgba(255, 255, 255, 0.6);
        }

        &:hover {
          background-position: right center; /* change the direction of the change here */
          color: #fff;
          text-decoration: none;
        }

        &:hover,
        &:disabled {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2f2f53e9;
    z-index: 11;

    .puff-loader {
    }

    .typewriter {
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      border-right: 0.15em solid orange; /* The typwriter cursor */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      letter-spacing: 0.15em; /* Adjust as needed */
      animation: typing 3.5s steps(40, end), blink-caret 0.5s step-end infinite;
      font-family: "Audiowide";
      width: auto;
    }

    /* The typing effect */
    @keyframes typing {
      from {
        width: 0;
      }
      to {
        width: 20%;
      }
    }

    /* The typewriter cursor effect */
    @keyframes blink-caret {
      from,
      to {
        border-color: transparent;
      }
      50% {
        border-color: orange;
      }
    }
  }
}
