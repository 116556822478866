.TiltTransform {
  /* transform: rotateX(35deg); */
  /* perspective: 1000px; */
  /* perspective-origin: top bottom; */
  /* transform-style: preserve-3d; */
  margin-bottom: 10%;
}

.Centerpiece {
  /* --center-square-size: calc(var(--square-size) * 0.28508);
  --circle-diameter: calc(var(--square-size) * 0.13146); */
  --center-square-size: calc(var(--square-size) * 0.34);
  /* --center-square-size: 110px; */
  --circle-diameter: calc(var(--square-size) * 0.175);

  /* width: var(--center-square-size);
  height: var(--center-square-size); */
  width: 130px;
  height: 100px;
  position: relative;

  --center-bg: #006f7e;
  --center-highlight: #001b20;
  /* --center-highlight: #006f7e; */

  background-image: url("../../assets/metacasino/ingame/center_square2.png");
  background-repeat: no-repeat;
  /* background-color: #2a1e16; */
  background-position: center;
  background-size: contain;
}

.Centerpiece-square {
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background: var(--center-highlight); */

  opacity: 1;
}

/* .Centerpiece-squareCut {
  width: calc(100% - 6px);
  height: calc(100% - 6px);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  background: var(--center-bg);
}

.Centerpiece-cut {
  clip-path: polygon(
    6% 0,
    94% 0,
    94% 0%,
    94% 3%,
    97% 6%,
    100% 6%,
    100% 94%,
    97% 94%,
    94% 97%,
    94% 100%,
    6% 100%,
    6% 97%,
    3% 94%,
    0% 94%,
    0 6%,
    3% 6%,
    6% 3%
  );
}
.Centerpiece-innerCut {
  clip-path: polygon(
    6.5% 0,
    93.5% 0,
    93.5% 0%,
    93.5% 2%,
    98% 6.5%,
    100% 6.5%,
    100% 93.5%,
    98% 93.5%,
    93.5% 98%,
    93.5% 100%,
    6.5% 100%,
    6.5% 98%,
    2% 93.5%,
    0% 93.5%,
    0 6.5%,
    2% 6.5%,
    6.5% 2%
  );
}

.Centerpiece-squareCut .Centerpiece-squareCut {
  width: calc(100% - 9px);
  height: calc(100% - 9px);
  background: var(--center-highlight);
}

.Centerpiece-innerCut .Centerpiece-innerCut {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: var(--center-bg);
} */

/*------ ROUND WIND #01F2DF ------*/
.Centerpiece-wind {
  width: var(--circle-diameter);
  height: var(--circle-diameter);
  border-radius: 50%;
  border: 3px solid var(--center-highlight);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--center-highlight);
  font-size: calc(var(--circle-diameter) * 0.7);
  /* font-family: "Noto Serif SC"; */
  font-weight: bold;
  overflow: visible;
  /* transform: translateZ(20px); */
}

.Centerpiece-wind-CN {
  margin-top: -5%;
  /* width: 100%; */
  font-size: 1.5rem;
  color: #b3e2ea;
  position: absolute;
  top: calc(60% - 1.2rem);
  /* left: calc(50% - 1.2rem); */
  font-family: "Noto Serif";
  text-align: center;

  /* text-align: center;
  font-size: 1.2rem;
  color: #b3e2ea;
  font-family: "Space Mono";
  transform: translateZ(50px);
  position: absolute;
  top: calc(50% - 1.2rem);
  left: 0; */
  animation: blink normal 1.5s infinite ease-in-out;
}

.Centerpiece-wind-EN {
  position: absolute;
  left: 90%;

  background: var(--center-bg);

  font-size: calc(var(--square-size) * 0.03545);
  font-family: "Source Serif Pro";
  text-transform: uppercase;
  letter-spacing: 0.12em;
  line-height: 1em;
}
.Centerpiece-wind-EN ~ .Centerpiece-wind-EN {
  right: 90%;
  left: unset;
  transform: rotate(180deg);
}

/*------ TILES REMAINING #01F2DF ------*/
.Centerpiece-tilesRemaining {
  position: absolute;
  bottom: 10%;

  /* font-size: calc(var(--center-square-size) * 0.0673); */
  font-size: 0.58rem;
  color: var(--center-highlight);
}

.Centerpiece-tilesRemaining ~ .Centerpiece-tilesRemaining {
  top: 12%;
  bottom: unset;

  transform: rotate(180deg);
}

/*------ PLAYED TILES #01F2DF ------*/
.Centerpiece-playedTiles {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;

  width: var(--center-square-size);
  height: var(--center-square-size);
  /* border: #b3e2ea solid 3px; */
}

.Centerpiece-tile-bottom {
  bottom: 0;
  top: 6px;
  /* left: calc(-1 * (240px - var(--center-square-size)) / 2); */
  left: 0;
  transform: translateY(100%) translateX(calc(-280px / 4));
  transform-origin: bottom left;
  width: 280px;
  height: 95px;
}
.Centerpiece-tile-top {
  top: -4px;
  /* left: calc(-1 * (240px - var(--center-square-size)) / 2); */
  left: 0;
  /* transform: translateY(-100%) rotate(180deg); */
  transform: translateY(-100%) translateX(0%);
  transform-origin: bottom left;
  width: 240px;
  height: 60px;
}
.Centerpiece-tile-left {
  left: 0;
  top: 4px;
  transform: translateY(-2%) rotate(90deg);
  /* transform: rotate(90deg); */
  height: 200px;
  transform-origin: top left;
}
.Centerpiece-tile-right {
  right: 0;
  bottom: 4px;
  transform: translateY(2%) translateX(300%) rotate(-90deg);
  transform-origin: bottom left;
  height: 200px;
}

.Centerpiece-playedTiles .OpenTile-container {
  --tile-width: calc(var(--center-square-size) * 0.2);

  margin-top: calc(var(--center-square-size) * 0.01);
  margin-right: calc(var(--center-square-size) * 0.01);
  background-color: white;
  opacity: 0.7;
}

.Centerpiece-playedTiles .OpenTile-container.isLastPlayed {
  background-color: white;
  opacity: 1;
  box-shadow: var(--glowShadow);
}

/*------ TURN MARKER #01F2DF ------*/
.Centerpiece-turn {
  --marker-size: calc(var(--center-square-size) * 0.06735);

  position: absolute;
  opacity: 0;

  border-style: solid;
  border-width: var(--marker-size) var(--marker-size) 0 var(--marker-size);
  /* border-color: var(--center-highlight) transparent transparent transparent; */
  border-color: white transparent transparent transparent;
  z-index: 100;
}

.Centerpiece-turn-bottom {
  bottom: calc(var(--circle-diameter) / 2);
  transform: translateY(6px);
}
.Centerpiece-turn-top {
  top: calc(var(--circle-diameter) / 2);
  transform: translateY(-0px) rotate(180deg);
  transform-origin: top;
}
.Centerpiece-turn-left {
  left: calc(var(--circle-diameter) / 2);
  transform: translateX(6px) rotate(90deg);
  transform-origin: center;
}
.Centerpiece-turn-right {
  right: calc(var(--circle-diameter) / 2);
  transform: translateX(-6px) rotate(-90deg);
  transform-origin: center;
}

.Centerpiece-isTurn {
  opacity: 1;
  animation: blink normal 1.5s infinite ease-in-out;
}

.waiting-text {
  position: absolute;
  font-size: 1.5rem;
  animation: blink normal 1.5s infinite ease-in-out;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
