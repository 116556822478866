.animationStage-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  pointer-events: none;
}

.overlay_action {
  width: 100%;
  height: 100%;
  z-index: 150;
  // transform: translateZ(50px);
}
