.CallOut-container {
  --tile-width: calc(var(--square-size) * 0.06208);
  --tile-height: calc(var(--tile-width) * 4 / 3);
  --pos-over-seat: calc(var(--square-size) * 0.4);
  --pos-over-tiles: calc(var(--tile-height) * -4);
  --padding: calc(var(--tile-width) * 0.8);

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform-origin: center;
  flex-direction: row;

  animation-name: new-callout-animation;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-duration: 1.8s;
  animation-timing-function: ease-out;

  text-align: center;
  font-family: "Source Serif Pro";
  font-size: calc(var(--tile-height) * 0.6);

  height: calc(var(--tile-height) * 1.2);
  width: calc(var(--tile-height) * 4);

  /* padding: 0 var(--padding); */
  z-index: 9;
}

.Player-bottom .CallOut-container {
  left: var(--pos-over-seat);
  top: var(--pos-over-tiles);
  transform: translate(-50%);
}
.Player-left .CallOut-container {
  right: calc(var(--pos-over-tiles) - 20px);
  top: var(--pos-over-seat);
  transform: translate(20%, -50%);
}
.Player-right .CallOut-container {
  left: calc(var(--pos-over-tiles) - 40px);
  /* top: var(--pos-over-seat); */
  top: 60px;
  transform: translate(-20%, -50%);
}
.Player-top .CallOut-container {
  left: var(--pos-over-seat);
  top: calc(-0.7 * var(--pos-over-tiles));
  transform: translate(-50%, -20%);
}

.CallOut-EN {
  position: relative;
  font-family: "Noto Serif SC";
  font-size: calc(var(--tile-height) * 0.8);
  color: var(--paper);
  z-index: 1;
  padding-right: 12px;
}

.CallOut-CN {
  position: absolute;
  /* left: 4%; */
  bottom: calc(var(--tile-height) * -0.1);
  z-index: 1;

  color: var(--paper);
  font-family: "Noto Serif SC";
  font-size: calc(var(--tile-height) * 1);
  text-shadow: 1px 2px #595959;
}

.CallOut-tile {
  color: var(--ink);

  background-image: url("assets/Brushes/InkyBrush.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.CallOut-take {
  /* color: var(--paper); */
  color: #7dcfba;
  font-weight: 600;
  font-size: calc(var(--tile-height) * 0.6);
  text-transform: uppercase;
}

.CallOut-take .CallOut-EN {
  text-align: left;
  /* margin-top: 16%; */
  transform: translateX(calc(var(--tile-width) * 0.5));
}

.CallOut-brush {
  width: calc(var(--tile-width) * 4.5);
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.CallOut-brush.CallOut-gang {
  color: var(--liquid);
  opacity: 0.5;
  /* color: #1ed8d2c9; */
}
.CallOut-brush.CallOut-peng {
  color: var(--jade);
}
.CallOut-brush.CallOut-chi {
  color: var(--poppy);
}
.CallOut-brush.CallOut-hu {
  color: var(--gold);
}

.pointChanges-win {
  z-index: 2;
  color: #c3fe1e;
  font-size: 1.4rem;
  position: absolute;
  top: -20px;
  right: 0;
  text-shadow: 1px 2px #595959;
  /* padding-left: 10px; */
}
.pointChanges-lose {
  z-index: 2;
  color: #ffbcbc;
  font-size: 1.2rem;
  position: absolute;
  top: -22px;
  right: -5px;
  text-shadow: 1px 2px #595959;
  /* padding-left: 10px; */
}

@keyframes new-callout-animation {
  from {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  to {
    opacity: 0;
    /* opacity: 1; */
  }
}
