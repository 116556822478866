.actionAnimation-container {
  position: absolute;
  top: 0;
  // bottom: 0;
  left: 0;
  // right: 0;
  // width: 100px;
  // height: 100px;
  pointer-events: none;

  .button_spark {
    width: 100%;
    height: 100%;
    z-index: 150;
    transform-origin: center;
    // transform: scale(2, 2) translateZ(50px);
    transform: scale(2, 2);
    animation: actionFadeIn 0.6s;
    // animation-fill-mode: forwards;
    // animation-iteration-count: 1;
    // opacity: 0;
  }
}

@keyframes actionFadeIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
